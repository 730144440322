<template>
    <section>
        <div class="limiter-content pa-2 pa-lg-0">
            <div class="padding-block"></div>

            <v-row justify="center">
                <v-col lg="12">
                    <h1 class="section-page-title">
                        <span class="primary--text">modelos</span><br><span
                            class="section-page-title-underline darkSecondary--text"><strong>de documentos</strong></span>
                    </h1>
                </v-col>
            </v-row>

            <br />

            <div v-html="dataset.description"></div>

            <br />

            <v-list>
                <a v-for="(item, index) in dataset.documents" :key="index" :href="item.file_url"
                    class="pa-0 d-flex  flex-column align-start list-item-link mb-2  ">
                    <v-col class="pa-0">
                        <v-divider class="primary"></v-divider>
                        <v-list-item class="d-flex align-center">
                            <v-list-item-avatar>
                                <v-img src="@/assets/logo-bej-small.png"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-uppercase font-weight-medium mediumGray--text">{{
                                    item.name
                                    }}</v-list-item-title>
                            </v-list-item-content>
                            <v-icon>mdi-download</v-icon>
                        </v-list-item>
                    </v-col>
                </a>
                <v-divider class="primary"></v-divider>
            </v-list>

            <v-row>
                <whatsButton />
            </v-row>

            <v-row>
                <topButton />
            </v-row>
        </div>
    </section>
</template>

<script>
import 'moment/locale/pt-br';
import axios from 'axios';

import { eventHub } from "@/main";
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';

export default {
    components: {
        topButton,
        whatsButton,
    },

    data: () => ({
        user: null,
        dialogPopup: false,
        dataset: {
            documents: [],
            description: '',
        }
    }),

    mounted: function () {
    },

    created() {
        this.$ga.event({
            eventCategory: 'page',
            eventAction: 'action',
            eventLabel: 'click',
            eventValue: 'home'
        })

        this.loadText();
        this.loadDocuments();
    },

    methods: {
        openRegister() {
            eventHub.$emit("DIALOG-REGISTER", true)
        },

        async loadText() {
            try {
                const result = await axios.get(`/api/dynamic-infos/keys`, {
                    params: { keys: 'documents-template-description' }
                });

                if (result.data.content && result.data.content.length > 0) {
                    this.dataset.description = result.data.content[0].value;
                } else {
                    this.dataset.description = '';
                }

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadDocuments() {

            try {
                const result = await axios.get('/api/document-models')

                this.dataset.documents = result.data.content.data

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 5000
                })
            }
        }

    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: "Raleway";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}
</style>
