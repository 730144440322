<template>
    <section class="limiter-content">
        <div class="padding-block"></div>

        <v-row justify="center">
            <v-col lg="12">
                <h1 class="section-page-title">
                    <span class="primary--text">na</span> <span
                        class="section-page-title-underline darkSecondary--text"><strong>mídia</strong></span>
                </h1>
            </v-col>
        </v-row>

        <br />

        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="200"
            infinite-scroll-throttle-delay="1000">
            <v-row>
                <v-col sm="4" v-for="news in dataList.data" :key="news.id">
                    <news-card :news="news" />
                </v-col>
            </v-row>
        </div>

        <v-row>
            <whatsButton />
        </v-row>

        <v-row>
            <topButton />
        </v-row>
    </section>
</template>

<script>
import NewsCard from '@/components/layout/NewsCard.vue'
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';

import axios from 'axios'

export default {
    components: {
        NewsCard,
        topButton,
        whatsButton,
    },

    data() {
        return {
            busy: false,
            dataList: {
                filter: {
                    name: '',
                    registrationNumber: '',
                    city: '',
                    status: ''
                },
                data: [],
                loading: false,
                count: 0,
                options: {
                    page: 1,
                    size: 25,
                    sortBy: ['name'],
                    sortDesc: [false]
                },
                footerOptions: {
                    itemsPerPageText: 'Itens por página:',
                    itemsPerPageOptions: [25, 25]
                }
            }
        }
    },

    created() {
        this.load()
    },

    methods: {
        loadMore: function () {
            this.busy = true

            // setTimeout(() => {
            //     for (var i = 0, j = 10; i < j; i++) {
            //         this.data.push({ name: count++ });
            //     }
            //     this.busy = false;
            // }, 1000);

        },

        async load() {
            this.dataList.loading = true

            var limit = this.dataList.options.size

            try {
                const result = await axios.get('/api/posts', {
                    params: { page: this.dataList.options.page, limit: limit, search: this.filter }
                })

                this.dataList.data = result.data.content.data
                this.dataList.count = result.data.content.paging.total
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
            } finally {
                this.dataList.loading = false
            }
        }
    }
}
</script>
