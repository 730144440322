<template>
  <section class="">
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="12">
          <h1 class="section-page-title">
            <span class="primary--text">processos</span><br><span class="section-page-title-underline darkSecondary--text"><strong>MAIS CONSULTADOS</strong></span>
          </h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="3" v-for="(item, index) in dataList" :key="index">
          <company-card-home-processes :id="item.client.id" :link-href="item.linkHref" :image-url="item.client.logo_url"
            :passivo="item.client.total_value" :credores="item.client.total_creditors"
            :name="item.client.name"></company-card-home-processes>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import CompanyCardHomeProcesses from '@/components/home/CompanyCardHomeProcesses.vue'
import axios from "axios";

export default {
  name: 'MostAcessedProcesses',
  components: {
    CompanyCardHomeProcesses
  },
  data() {
    return {
      dataList: [],
    }
  },

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'contact'
    })

    this.load()
  },

  methods: {

    async load() {

      try {
        const result = await axios.get('/api/most-consulted-processes')

        this.dataList = result.data.content

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss">
.font-title {
  font-size: 30px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
}

.underline::after {
  content: '';
  display: block;
  margin: 5px 0;
  height: 3px;
  width: 180px;
  background: var(--v-primary-base);
}
</style>
