import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Team from "@/views/Team.vue";

import CompanyJudicialRecoveryPage from "@/views/CompanyJudicialRecoveryPage.vue";

import JudicialRecoveries from "@/views/cases/JudicialRecoveries.vue";
import Bankruptcies from "@/views/cases/Bankruptcies.vue";
import JudicialLiquidation from "@/views/cases/JudicialLiquidation.vue";
import CivilInsolvency from "@/views/cases/CivilInsolvency.vue";
import CasesDetails from "@/views/cases/CasesDetails.vue";

import InMedia from '@/views/News.vue';
import NewsDetails from '@/views/NewsDetails.vue';

import QuestionsList from "@/views/QuestionsList.vue";
import Contact from "@/views/Contact.vue";

import CreditorArea from "@/views/CreditorArea.vue";

import SendQualificationsAndDivergences from "@/views/SendQualificationsAndDivergences.vue";
import UserEvents from "@/views/UserEvents.vue";
import CreateUserEvents from "@/views/CreateUserEvents.vue";
import EditUserEvents from "@/views/EditUserEvents.vue";
import DocumentsTemplates from "@/views/DocumentsTemplates.vue";
import DocumentCenterPage from "@/views/DocumentCenterPage.vue";
import CasesResume from "@/views/CasesResume.vue";

import CreditorsAssembly from "@/views/CreditorsAssembly.vue";
import AccreditationPage from "@/views/AccreditationPage.vue";
import VotingPage from "@/views/VotingPage.vue";
import PPPRequest from "@/views/PPPRequest.vue";


//the route guard
const guard = function (to: any, from: any, next: any) {
  const token = localStorage.getItem("token");

  const publicPath = to.matched.find(
    (path) => path.name === "Public" || path.name === "Home"
  );
  if (!token && to.path !== "/login" && !publicPath) {
    next({
      path: "/home",
    });
  } else {
    next();
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/Public.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
      },
      {
        path: "/equipe",
        name: "team",
        component: Team,
      },
      {
        path: "/contato",
        name: "contact",
        component: Contact,
      },
      {
        path: "/casos/recuperacoes",
        name: "judicialrecoveries",
        component: JudicialRecoveries,
      },
      {
        path: "/casos/recuperacoes/company",
        name: "companyjudicialrecoveries",
        component: CompanyJudicialRecoveryPage,
      },
      {
        path: "/casos/falencias",
        name: "bankruptcies",
        component: Bankruptcies,
      },
      {
        path: "/casos/liquidacao-judicial",
        name: "judicialliquidation",
        component: JudicialLiquidation,
      },
      {
        path: "/casos/insolvencia-civil",
        name: "civilinsolvency",
        component: CivilInsolvency,
      },
      {
        path: "/casos/detalhes/:id",
        name: "CasesDetails",
        component: CasesDetails,
      },
      {
        path: "/na-midia",
        name: "News",
        component: InMedia,
      },
      {
        path: "/na-midia/:id",
        name: "NewsDetails",
        component: NewsDetails,
      },
      {
        path: "/duvidas",
        name: "QuestionsList",
        component: QuestionsList,
      },
      // {
      //   path: "/area-credor",
      //   name: "CreditorArea",
      //   component: CreditorArea,
      // },
      {
        path: "/envio-habilitacoes-divergencias",
        name: "sendqualificationsanddivergences",
        component: SendQualificationsAndDivergences,
      },
      {
        path: "/habilitacoes-divergencias",
        name: "UserEvents",
        component: UserEvents,
      },
      {
        path: "/habilitacao-e-divergencias-online",
        name: "UserEvents",
        component: UserEvents,
      },
      {
        path: "/habilitacoes-divergencias/novo",
        name: "CreateUserEvents",
        component: CreateUserEvents,
      },
      {
        path: "/habilitacoes-divergencias/:id",
        name: "EditUserEvents",
        component: EditUserEvents,
      },
      {
        path: "/modelos-documentos",
        name: "documentstemplates",
        component: DocumentsTemplates,
      },
      {
        path: "/assembleia-credores",
        name: "CreditorsAssembly",
        component: CreditorsAssembly,
      },
      {
        path: "/assembleia-credores/credenciamento",
        name: "AccreditationPage",
        component: AccreditationPage,
      },
      {
        path: "/assembleia-credores/votacao/:id",
        name: "VotingPage",
        component: VotingPage,
      },
      {
        path: "/central-documentos",
        name: "DocumentCenterPage",
        component: DocumentCenterPage,
      },
      {
        path: "/resumo-geral",
        name: "CasesResume",
        component: CasesResume,
      },
      {
        path: "/solicitacao-ppp",
        name: "ppprequest",
        component: PPPRequest,
      },
      {
        path: "/",
        redirect: "/home",
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
