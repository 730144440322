<template>
  <div class="limiter-content pa-3 pa-lg-0">
    <div class="padding-block"></div>

    <v-row no-gutters justify="center" class='mb-5'>
      <v-col lg="12">
        <h1 class="custom-title-page  font-weight-bold">
          ÁREA DE <span class="underline primary--text">VOTAÇÃO</span>
        </h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">

        <v-card>
          <v-card-title class="page-subtitle">Detalhes da Assembléia</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="black--text vote-card mb-4">
                <v-col>
                  <strong>Caso: </strong> <span>{{ dataset.data.client_name }}</span>
                  <br>
                  <strong>Assembléia: </strong> <span>{{ dataset.data.general_meeting_name }}</span>
                  <br>
                  <strong>Votação: </strong> <span>{{ dataset.data.voting_name }}</span> 
                </v-col>
              </v-col>
            </v-row>
            <br>

            <v-row v-if="dataset.data.status === 1">
              <v-col>
                <v-banner single-line outlined>
                  <v-icon slot="icon" color="grey" size="36">
                    mdi-progress-alert
                  </v-icon>
                  Sem votação
                </v-banner>
              </v-col>
            </v-row>

            <v-row v-if="dataset.data && dataset.data.voting_status === 2">
              <v-col>
                <v-banner single-line outlined>
                  <v-icon slot="icon" color="green" size="36">
                    mdi-progress-clock
                  </v-icon>
                  Votação em andamento
                </v-banner>
              </v-col>
            </v-row>

            <v-row v-if="dataset.data && dataset.data.vote">
              <v-col>
                <v-banner single-line outlined>
                  <v-icon slot="icon" color="green" size="36">
                    mdi-vote-outline
                  </v-icon>
                  Você votou o plano e seu voto é
                  <strong :style="{ color: voteColor(dataset.data.vote) }">
                    {{ voteLabel(dataset.data.vote) }}
                  </strong>
                </v-banner>
              </v-col>
            </v-row>

            <v-row v-if="dataset.data && !dataset.data.vote">
              <v-col>
                <v-banner single-line outlined>
                  <v-icon slot="icon" color="red" size="36">
                    mdi-vote-outline
                  </v-icon>
                  Você ainda não votou o plano!
                </v-banner>
              </v-col>
            </v-row>

            <v-row v-if="dataset.data && dataset.data.voting_status === 3">
              <v-col>
                <v-banner single-line outlined>
                  <v-icon slot="icon" color="warning" size="36">
                    mdi-alert-outline
                  </v-icon>
                  Votação pausada
                </v-banner>
              </v-col>
            </v-row>

            <v-row v-if="dataset.data && dataset.data.voting_status === 4">
              <v-col>
                <v-banner single-line outlined>
                  <v-icon slot="icon" color="grey" size="36">
                    mdi-progress-check
                  </v-icon>
                  Votação finalizada
                </v-banner>
              </v-col>
            </v-row>
            <br>

            <template v-if="dataset.data.voting_status === 2">
              <v-sheet class="vote-card mt-4" single-line outlined>
                <v-card-text>
                  <v-row class="justify-center">
                    <v-col cols="4">
                      <v-chip class="vote-chip" :class="{ 'vote-selected': vote === 'yes' }" color="green" outlined
                        @click="setVote('yes')" :input-value="vote === 'yes'">
                        <v-icon left>mdi-thumb-up</v-icon>
                        SIM
                      </v-chip>
                    </v-col>
                    <v-col cols="4">
                      <v-chip class="vote-chip" :class="{ 'vote-selected': vote === 'no' }" color="red" outlined
                        @click="setVote('no')" :input-value="vote === 'no'">
                        <v-icon left>mdi-thumb-down</v-icon>
                        NÃO
                      </v-chip>
                    </v-col>
                    <v-col cols="4">
                      <v-chip class="vote-chip" :class="{ 'vote-selected': vote === 'null' }" color="grey" outlined
                        @click="setVote('null')" :input-value="vote === 'null'">
                        <v-icon left>mdi-cancel</v-icon>
                        NULO
                      </v-chip>
                    </v-col>

                    <br>
                    <br>
                    <br>

                    <v-row class="justify-start">
                      <v-col cols="12" v-if="vote">
                        <v-banner>
                          <template v-slot:icon>
                            <v-icon :color="voteIcon.color" size="36">
                              {{ voteIcon.icon }}
                            </v-icon>
                          </template>
                          Sua opção de voto
                        </v-banner>
                      </v-col>
                      <v-col v-else>
                        <br>
                        <span>Escolha uma opção de voto</span>
                      </v-col>
                      <v-col cols="12" v-if="vote">

                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn v-on="on" color="primary" :loading="dataset.loading"
                              @click="submitVote">Salvar</v-btn>
                          </template>
                          <span>Salvar opção de voto</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card-text>
              </v-sheet>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- GRAFICOS -->
    <v-row>
      <v-col>
        <v-card class="card-form-outlined table-card">
          <v-card-title class="table-card-title">
            SIM
          </v-card-title>
          <v-card-text>
            <v-row align="center" class="mb-2" no-gutters>
              <v-col sm="12">
                <v-progress-linear class="mb-2" :background-opacity="0.4" :value="yesPercentage" :height="20"
                  color="green"></v-progress-linear>

                <span>
                  <strong> {{ yesPercentage.toFixed(1) }} %</strong>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card class="card-form-outlined table-card">
          <v-card-title class="table-card-title">
            NÃO
          </v-card-title>
          <v-card-text>
            <v-row align="center" class="mb-2" no-gutters>
              <v-col sm="12">
                <v-progress-linear class="mb-2" :background-opacity="0.4" :value="noPercentage" :height="20"
                  color="red"></v-progress-linear>

                <span>
                  <strong> {{ noPercentage.toFixed(1) }} %</strong>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card class="card-form-outlined table-card">
          <v-card-title class="table-card-title">
            NULO
          </v-card-title>
          <v-card-text>
            <v-row align="center" class="mb-2" no-gutters>
              <v-col sm="12">
                <v-progress-linear class="mb-2" :background-opacity="0.4" :value="nullPercentage" :height="20"
                  color="#ccc"></v-progress-linear>

                <span>
                  <strong> {{ nullPercentage.toFixed(1) }}%</strong>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <v-row class="mt-6">
      <v-col>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn class="ml-4" v-on="on" type="button" elevation="0" color="primary" outlined
              @click="$router.go(-1)">Voltar</v-btn>
          </template>
          <span>Retornar para Assembleia de Credores</span>
        </v-tooltip>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { eventHub } from '@/main';

import axios from 'axios';
import itemTypes from "@/common/data/item-types.data.js";
export default {
  name: 'VotingPage',

  data() {
    return {
      totalVotes: 0,
      yesVotes: 0,
      noVotes: 0,
      nullVotes: 0,

      intervalId: null,

      vote: null,
      meeting: {},
      user: null,
      valid: true,
      panel: [0],
      e1: 1,
      participationType: '',
      clientId: null,
      meetingInfo: null,
      dataset: {
        itemTypes: itemTypes,
        loading: false,
        generalMeeting: {},
        data: {
          case_name: null,
          general_meeting_name: null,
          voting_id: null,
          voting_name: null,
          voting_status: null,
        },
        votingResult: null,
        isFirstLoad: true,
      },
    };
  },


  created() {
    //this.user = this.$root.user;

    // if (!this.$route.query.id)
    //   this.$router.push(`/assembleia-credores/votacao`)

    if (this.$root.user) {
      this.user = this.$root.user;
    }

  },

  mounted() {
    this.loadVotingResult();
  },

  destroyed() {
    this.stopAutoUpdate();
  },

  computed: {
    yesPercentage() {
      return this.calculatePercentage(this.yesVotes);
    },

    noPercentage() {
      return this.calculatePercentage(this.noVotes);
    },

    nullPercentage() {
      return this.calculatePercentage(this.nullVotes);
    },

    voteIcon() {
      switch (this.vote) {
        case 'yes':
          return { icon: 'mdi-thumb-up', color: 'green' };
        case 'no':
          return { icon: 'mdi-thumb-down', color: 'red' };
        case 'null':
          return { icon: 'mdi-cancel', color: 'grey' };
        default:
          return { icon: 'mdi-progress-alert', color: 'warning' };
      }
    },
  },


  methods: {

    setVote(value) {
      this.vote = value;
    },

    voteLabel(vote) {
      switch (vote) {
        case 1:
          return 'SIM';
        case 2:
          return 'NÃO';
        case 3:
          return 'NULO';
        default:
          return 'Indefinido';
      }
    },

    voteColor(vote) {
      switch (vote) {
        case 1:
          return 'green';
        case 2:
          return 'red';
        case 3:
          return 'grey';
        default:
          return 'black';
      }
    },

    calculatePercentage(votes) {

      if (this.totalVotes === 0) {
        return 0;
      }
      return (votes / this.totalVotes) * 100;

    },

    resolveColorDeadlineAccreditation(status) {

      if (status > 0 && status < 50)
        return '#FFCC80';

      if (status > 50 && status < 80)
        return '#F9AA33';

      if (status > 80 && status < 90)
        return '#FF5252';

      return '#FF5252';

    },


    async submitVote() {

      if (!this.vote) {

        this.$dialog.notify.error("Selecione uma opção de voto!", { position: 'top-right', timeout: 5000 });
      }

      this.dataset.loading = true;

      const payload = {
        general_meeting_voting_id: this.$route.params.id,
        vote: this.vote === "yes" ? 1 : this.vote === "no" ? 2 : this.vote === "null" ? 3 : null
      };

      console.log("payload: ", payload)

      try {
        const response = await axios.post('/api/general-meeting-votings', payload)

        this.$dialog.notify.success("Voto contabilizado com sucesso!", { position: 'top-right', timeout: 5000 });

        this.dataset.data.vote = this.vote === "yes" ? 1 : this.vote === "no" ? 2 : this.vote === "null" ? 3 : null;

        this.vote = null;

        this.loadVotingResult();

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 5000
        });
      } finally {
        this.dataset.loading = false;
      }
    },

    startAutoUpdate() {
      if (!this.intervalId) {
        this.intervalId = setInterval(() => {
          this.loadVotingResult();
        }, 15000); // 15 segundos
      }
    },

    stopAutoUpdate() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },

    async loadVotingResult() {

      try {

        const general_meeting_voting_id = this.$route.params.id;

        const action = this.dataset.isFirstLoad

        const response = await axios.get(`/api/general-meeting-votings-request`, { 
          params: { 
            general_meeting_voting_id,
            action
          } 
        });

        this.dataset.votingResult = response.data.content.data;

        if(!response.data.content.assembly){

          this.$dialog.notify.error("Você não tem acesso a esta votação!", { position: 'top-right', timeout: 5000 });

          this.stopAutoUpdate();

          return;

        }else{

          this.totalVotes = response.data.content.data[0].total_votes
          this.yesVotes = response.data.content.data[0].total_vote_1
          this.noVotes = response.data.content.data[0].total_vote_2
          this.nullVotes = response.data.content.data[0].total_vote_3

          if(this.dataset.isFirstLoad){
            this.dataset.data.general_meeting_name = response.data.content.assembly[0].gm_name
            this.dataset.data.voting_name = response.data.content.assembly[0].gmv_name
            this. dataset.data.client_name = response.data.content.assembly[0].client_name
            this.dataset.data.vote = response.data.content.vote
            this.dataset.data.voting_status = response.data.content.assembly[0].gmv_status
          }

        }

        this.dataset.isFirstLoad = false;

        this.startAutoUpdate();

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        });

        this.stopAutoUpdate();
      }
    },

  }
};

</script>


<style>
.vote-card {
  border: 1px solid #1976d2;
  padding: 16px;
  border-radius: 10px;
}

.vote-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.vote-chip {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: background-color 0.3s, border-color 0.3s;
}

.bar-container {
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.bar {
  width: 50px;
}

.yes-bar {
  background-color: #4caf50;
}

.no-bar {
  background-color: #f44336;
}

.null-bar {
  background-color: #ffeb3b;
}

.v-card-title {
  background-color: #fff;
  color: #000;
}

.v-card {
  background-color: #fff;
  color: #000;
}

.no-border-banner {
  border: none;
  box-shadow: none;
  background: none;
}
</style>