<template>
    <section class="limiter-content pa-2 pa-lg-0">
        <div class="padding-block"></div>

        <v-row justify="center">
            <v-col lg="12">
                <h1 class="section-page-title">
                    <span class="primary--text">formulário</span><br><span
                        class="section-page-title-underline darkSecondary--text"><strong>de habilitações e divergências</strong></span>
                </h1>
            </v-col>
        </v-row>

        <br />

        <div v-html="!user ? dataset.contentUserAnonymous : dataset.contentUserLogged"></div>

        <v-form v-if="user" class="pt-10" ref="form" v-model="dataForm.validForm">


            <p class="font-weight-bold">Você gostaria de habilitar ou divergir de um crédito relacionado a qual empresa?
            </p>

            <v-row>
                <v-col cols="12" sm="12">
                    <v-autocomplete :disabled="dataset.loading" dense required :items="dataset.clients"
                        label="Selecione a devedora" v-model="dataset.data.client" item-value="id" item-text="name"
                        :rules="[$rules.required]" outlined hide-details="auto">
                    </v-autocomplete>
                </v-col>
            </v-row>

            <template v-if="dataset.data.client">
                <v-row>
                    <v-col>
                        <strong>Negócios</strong>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <p v-if="!dataset.loading">
                            Para adicionar um novo negócio,
                            <a href="#" @click.prevent="addItem"><strong>clique aqui</strong></a>
                        </p>
                    </v-col>
                </v-row>
            </template>

            <div v-for="(item, i) in dataset.data.items" :key="i">
                <v-row>
                    <v-col>
                        <strong>Negócio #{{ getItemNumber(i) }}</strong>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <div>
                                        <strong>Origem: </strong>
                                        <span>{{
                                            truncateText(item.origin || 'N/A', 10)
                                            }}</span>
                                        /
                                        <strong>Valor: </strong>
                                        <span v-if="item.amount">{{
                                            item.currency
                                            | enum(
                                                dataset.currencyTypes,
                                                'id',
                                                'currencyFormat'
                                            )
                                            }}
                                            {{ item.amount | currency }}</span><span v-else>N/A</span>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col cols="12">
                                            <span class="mr-3"> <strong>Informe a origem do negócio</strong></span>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small :color="'primary'" v-bind="attrs"
                                                        v-on="on">mdi-help-circle</v-icon>
                                                </template>
                                                <span>Digite o motivo que gerou os valores do negócio</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>

                                            <v-text-field :disabled="dataset.loading" dense label="Origem do negócio"
                                                v-model="item.origin" required :rules="[$rules.required]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <span class="mr-3"> <strong>Informe a moeda e o valor referente ao
                                                    negócio</strong></span>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small :color="'primary'" v-bind="attrs"
                                                        v-on="on">mdi-help-circle</v-icon>
                                                </template>
                                                <span>Informe o tipo de moeda e o valor devido</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select :disabled="dataset.loading" dense id="type"
                                                :items="dataset.currencyTypes" label="Selecione uma moeda"
                                                v-model="item.currency" item-value="id" item-text="name" outlined
                                                hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-currency-field :disabled="dataset.loading" label="Valor" dense
                                                v-model="item.amount" :allowNegative="false" hide-details="auto"
                                                outlined required :rules="[$rules.required]">
                                            </v-currency-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <span class="mr-3"> <strong>Informe a sua classe no processo</strong></span>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small :color="'primary'" v-bind="attrs"
                                                        v-on="on">mdi-help-circle</v-icon>
                                                </template>
                                                <span>Selecione a sua classe no processo</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select dense :items="selectCreditorTypes()" label="Classe"
                                                v-model="item.type" item-value="id" item-text="name" outlined
                                                hide-details="auto" :rules="[$rules.required]">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <!-- DOCS COMPROBATÓRIOS -->

                                    <v-row>
                                        <v-col cols="12" lg="12">
                                            <p>
                                                <strong>Adicione documentos comprobatórios
                                                    do negócio</strong>
                                            </p>

                                            <v-stepper v-model="item.step" vertical flat>

                                                <!-- STEP 1 -->
                                                <v-stepper-step :complete="item.step > 1" step="1">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4>Petição <span
                                                                    class='font-weight-light mr-10'>(opcional)</span>
                                                            </h4>
                                                            <small>Realize o envio da Petição</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 1 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre a Petição</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-step>

                                                <v-stepper-content step="1">

                                                    <v-text-field :disabled="dataset.loading" hide-details="auto"
                                                        prepend-icon="mdi-paperclip" outlined class='mt-1' dense
                                                        @click:clear="clearDocs(i, 5)" :clearable="true"
                                                        :value="getDocsByType(i, 5)" label="Petição"
                                                        @click.prevent="openDocumentModal(i, 5)">
                                                    </v-text-field>
                                                    <v-btn class='mt-3' color="primary" @click="onInput(i, 1)"
                                                        :disabled="!hasDocument(i, 5, false)">
                                                        Próximo
                                                    </v-btn>
                                                    <v-btn class='ml-3 mt-3' @click="onCancel(i, 1)" text>
                                                        Cancelar
                                                    </v-btn>
                                                </v-stepper-content>

                                                <!-- STEP 2 -->
                                                <v-stepper-step :complete="item.step > 2" step="2">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4> Procuração <span
                                                                    class='font-weight-light mr-5'>(opcional)</span>
                                                            </h4>
                                                            <small>Realize o envio da
                                                                Procuração</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 2 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre a Procuração</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-step>

                                                <v-stepper-content step="2">

                                                    <v-text-field :disabled="dataset.loading" readonly
                                                        hide-details="auto" prepend-icon="mdi-paperclip"
                                                        label="Procuração" outlined class='mt-1' dense :clearable="true"
                                                        @click:clear="clearDocs(i, 2)"
                                                        @click.prevent="openDocumentModal(i, 2)"
                                                        :value="getDocsByType(i, 2)">

                                                    </v-text-field>
                                                    <v-btn class='mt-3' color="primary" @click="onInput(i, 2)"
                                                        :disabled="!hasDocument(i, 2, false)">
                                                        Próximo
                                                    </v-btn>
                                                    <v-btn class='ml-3 mt-3' @click="onCancel(i, 2)" text>
                                                        Cancelar
                                                    </v-btn>
                                                </v-stepper-content>

                                                <!-- STEP 3 -->
                                                <v-stepper-step :complete="item.step > 3" step="3">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4> Planilha de Cálculos <span
                                                                    class='font-weight-light'>(opcional)</span></h4>
                                                            <small>Realize o envio da Planilha de
                                                                Cálculos</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 3 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre a Planilha de Cálculos</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>

                                                </v-stepper-step>

                                                <v-stepper-content step="3">

                                                    <v-text-field :disabled="dataset.loading" readonly
                                                        hide-details="auto" prepend-icon="mdi-paperclip" outlined
                                                        class='mt-1' dense @click:clear="clearDocs(i, 6)"
                                                        :clearable="true" :value="getDocsByType(i, 6)"
                                                        label="Planilha de Cáculos"
                                                        @click.prevent="openDocumentModal(i, 6)" />

                                                    <v-btn class='mt-3' color="primary" @click="onInput(i, 3)"
                                                        :disabled="!hasDocument(i, 6, false)">
                                                        Próximo
                                                    </v-btn>
                                                    <v-btn class='ml-3 mt-3' @click="onCancel(i, 3)" text>
                                                        Cancelar
                                                    </v-btn>
                                                </v-stepper-content>

                                                <!-- STEP 4 -->
                                                <v-stepper-step :complete="item.step > 4" step="4">


                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4>Documentos Comprobatórios <span
                                                                    class='font-weight-light'>(obrigatório)</span></h4>
                                                            <small>Realize o envio de Documentos Comprobatórios</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 4 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre os Documentos
                                                                    Comprobatórios</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-step>

                                                <v-stepper-content step="4">

                                                    <v-text-field :disabled="dataset.loading" readonly
                                                        hide-details="auto" prepend-icon="mdi-paperclip"
                                                        :clearable="true" @click:clear="clearDocs(i, 3)"
                                                        label="Documentos Comprobatórios"
                                                        @click.prevent="openDocumentModal(i, 3)" outlined class="mt-1"
                                                        dense :value="getDocsByType(i, 3)" :rules="[$rules.required]" />

                                                    <v-btn class='mt-3' color="primary" @click="onInput(i, 4)"
                                                        :disabled="!hasDocument(i, 3, true)">
                                                        Próximo
                                                    </v-btn>
                                                    <v-btn class='ml-3 mt-3' @click="onCancel(i, 4)" text>
                                                        Cancelar
                                                    </v-btn>
                                                </v-stepper-content>

                                                <!-- STEP 5 -->
                                                <v-stepper-step step="5">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4> Outros <span
                                                                    class='font-weight-light'>(opcional)</span></h4>
                                                            <small>Caso tenha mais documentos, faça o envio</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 5 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre Outros documentos</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-step>

                                                <v-stepper-content step="5">

                                                    <v-text-field :disabled="dataset.loading" readonly label="Outros"
                                                        prepend-icon="mdi-paperclip" :clearable="true"
                                                        @click:clear="clearDocs(i, 4)" hide-details="auto"
                                                        @click.prevent="openDocumentModal(i, 4)" outlined class="mt-1"
                                                        dense :value="getDocsByType(i, 4)">
                                                    </v-text-field>

                                                    <v-btn class='mt-3' @click="onCancel(i, 5)" text>
                                                        Cancelar
                                                    </v-btn>

                                                </v-stepper-content>
                                            </v-stepper>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12">
                                            <p>
                                                <strong>Informe uma observação referente ao
                                                    negócio</strong>
                                            </p>
                                            <v-textarea :disabled="dataset.loading" dense id="description"
                                                label="Informações Adicionais" :rules="[
                                                    $rules.maxlength(
                                                        item.observation,
                                                        500
                                                    )
                                                ]" counter="500" v-model="item.observation" outlined
                                                hide-details="auto" rows="2" auto-grow></v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col>
                                            <v-btn color="primary" text :loading="dataset.loading"
                                                @click.native="removeItem(i)">
                                                <v-icon> mdi-delete-outline </v-icon>
                                                Excluir</v-btn>
                                        </v-col>
                                    </v-row>

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
            </div>

            <v-row>
                <!-- <v-col>
                    <v-btn color="primary" :loading="dataset.loading" @click.native="save">Salvar</v-btn>
                </v-col> -->

                <v-col>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn v-on="on" color="primary" :loading="dataset.loading"
                                @click.native="save">Salvar</v-btn>
                        </template>
                        <span>Salvar informações</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn class="ml-4" v-on="on" type="button" elevation="0" color="primary" outlined
                                to="/habilitacoes-divergencias">Voltar</v-btn>
                        </template>
                        <span>Retornar para habilitações e divergências</span>
                    </v-tooltip>
                </v-col>
            </v-row>

        </v-form>


        <!-- MODAIS -->

        <v-dialog v-model="showDocumentModal" max-width="800px">
            <v-card>
                <v-card-title>
                    <v-row justify="center">
                        <v-col lg="12">
                            <h1 class="custom-modal-title text-uppercase black--text font-weight-bold">
                                Escolha um
                                <span class="underline text-uppercase primary--text">documento</span>
                            </h1>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-btn class='mb-3' @click="openAddModalDocument(currentDocTypeId)" color="primary">
                        Novo documento
                    </v-btn>
                    <v-expansion-panels v-model="panel" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Selecionar da Central de Documentos</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-list dense v-if="!loadingDocuments">
                                    <v-list-item v-for="(doc, index) in documentsCenter" :key="index">
                                        <v-list-item-action>
                                            <v-checkbox v-model="doc.isSelected" :disabled="currentDocType !== 3 && currentDocType !== 4 &&
                                                !doc.isSelected && isAnyDocumentSelected">
                                            </v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>{{ doc.name }}</v-list-item-content>
                                        <v-list-item-content>{{ getDocTypeName(doc.type) }}</v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-progress-circular v-else indeterminate></v-progress-circular>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-card-actions class='pa-0 mt-4'>
                        <v-btn color="primary" @click="confirmSelection">Confirmar</v-btn>
                        <v-btn @click="checkDocumentsBeforeClose" text>
                            Cancelar
                        </v-btn>
                        <!-- <v-btn color="error" @click="checkDocumentsBeforeClose">Fechar</v-btn> -->
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showAddDocumentModal" max-width="600px">
            <v-card>
                <v-card-title>
                    <v-row justify="center">
                        <v-col lg="12">
                            <h1 class="custom-modal-title text-uppercase black--text font-weight-bold">
                                Adicionar um
                                <span class="underline text-uppercase primary--text">documento</span>
                            </h1>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-form ref="formNewDocument" v-model="valid">
                    <!-- <v-card-text class='mt-1'>

                        <v-text-field outlined dense required :rules="[$rules.required]" v-model="newDocument.name"
                            label="Nome"></v-text-field>

                        <v-select :items="dataset.itemTypes" :rules="[$rules.required]" item-text="name" item-value="id"
                            v-model="newDocument.type" label="Tipo" outlined dense required></v-select>

                        <v-file-input outlined dense required prepend-icon="mdi-paperclip" :rules="[$rules.required]"
                            v-model='newDocument.file' label="Upload do Documento">
                        </v-file-input>

                    </v-card-text> -->

                    <v-card-text class='mt-1'>
                        <v-text-field outlined dense readonly :value="getDocTypeName(currentDocTypeId)"
                            label="Tipo de documento"></v-text-field>

                        <v-text-field outlined dense required :rules="[$rules.required]" v-model="newDocument.name"
                            label="Nome do documento"></v-text-field>

                        <v-file-input outlined dense required prepend-icon="mdi-paperclip" :rules="[$rules.required]"
                            v-model='newDocument.file' label="Selecionar documento">
                        </v-file-input>
                    </v-card-text>
                    <v-col sm='3' class="d-flex">
                        <v-btn @click="saveNewDocument" class='ml-auto' color="primary" block
                            :disabled="dataset.loading">
                            Enviar
                            <v-progress-circular v-if="dataset.loading" indeterminate color="white" size="20"
                                class="ml-2"></v-progress-circular>
                        </v-btn>
                        <v-btn class='ml-3' @click="closeAddDocumentsModal()" text>
                            Cancelar
                        </v-btn>
                    </v-col>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog max-width="800px" v-model="showConfirmationDialog">
            <v-card>
                <v-card-title>Confirmação</v-card-title>
                <v-card-text>Você possui documento(s) selecionado(s) que não foram salvos! Deseja sair sem salvar
                    suas alterações?</v-card-text>
                <v-card-actions>
                    <v-btn text @click="continueWithoutSaving">Não</v-btn>
                    <v-btn text @click="closeAlertModal">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- MODAIS -->

        <v-row>
            <whatsButton />
        </v-row>

        <v-row>
            <topButton />
        </v-row>

    </section>
</template>

<script>
import 'moment/locale/pt-br';

import { eventHub } from '@/main';

import currencyTypes from '@/common/data/currency-types.data';
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';
import itemTypes from "@/common/data/item-types.data.js";
import creditorTypes from "@/common/data/creditor-types.data.js";


import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';

export default {

    components: {
        topButton,
        whatsButton
    },

    data: () => ({
        user: null,
        valid: true,
        panel: [0],
        showConfirmationDialog: false,
        loadingDocuments: false,
        currentDocTypeName: '',
        dataset: {
            currencyTypes: currencyTypes,
            contentUserAnonymous: '',
            contentUserLogged: '',
            clients: [],
            selectedEventItem: -1,
            data: {
                items: []
            },
            loading: false,
            itemTypes: itemTypes,
            creditorTypes: creditorTypes,
        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null
        },
        showDocumentModal: false,
        showAddDocumentModal: false,
        documentsCenter: [],
        newDocument: {},
        selectedPetitionDocument: null,
        selectedProcurationDocument: null,
        selectedSpreadsheet: null,
        selectSuportingDocument: [],
        selectOtherDocuments: [],
        currentDocTypeId: null,
    }),

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    mounted: function () { },

    computed: {
        isAnyDocumentSelected() {
            if (this.currentDocType === 'idOtherDocuments') {
                return false;
            }
            return this.documentsCenter.some(doc => doc.isSelected);
        },

        // selectCreditorTypes() {

        //     console.log("client: ", dataset.data.client)
        //     if (this.isEventOpened) {
        //         return this.dataset.creditorTypes;
        //     } else {
        //         return this.dataset.creditorTypes.filter(type => type.id === 1);
        //     }
        // }
    },


    created() {
        if (this.$root.user) {
            this.loadUserInfo();
            this.loadClients();
        }

        this.loadText();

        if (this.$route.query.token) {
            this.verifyAccount(this.$route.query.token);
        }
    },

    methods: {

        getDocTypeName(docTypeId) {
            let docType = this.dataset.itemTypes.find(type => type.id === docTypeId);
            return docType ? docType.name : 'Tipo Desconhecido';
        },

        selectCreditorTypes() {

            const client_id = this.dataset.data.client

            let client = this.dataset.clients.find(item => item.id == client_id);

            if (client.is_event_opened === true) {
                return this.dataset.creditorTypes;
            } else {
                return this.dataset.creditorTypes.filter(type => type.id === 1);
            }
        },

        checkDocumentsBeforeClose() {
            if (this.documentsCenter.some(doc => doc.isSelected)) {
                this.showConfirmationDialog = true;
            } else {
                this.closeModal();
            }
        },

        clearDocs(index, type) {

            const filteredDocs = this.dataset.data.items[index].docs.filter((x) => x.type != type)

            // this.dataset.data.items[index].docs = this.dataset.data.items[index].docs.filter(doc => doc.type !== type);]

            console.log(index, filteredDocs)

            this.dataset.data.items[index].docs = filteredDocs

            return true;
        },

        closeAddDocumentsModal() {

            this.newDocument.name = null;
            this.newDocument.file = null;

            this.showAddDocumentModal = false;
        },

        closeAlertModal() {
            this.showConfirmationDialog = false;
            this.closeModal();
        },
        continueWithoutSaving() {
            this.showConfirmationDialog = false;
        },
        truncateText(text, maxLength) {
            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        },

        getDocsByType(index, type) {

            const result = this.dataset.data.items[index].files.filter((x) => x.type == type).map((x) => x.name).join("; ");

            console.log("Valor retornado: ", result);

            return result;
        },

        // getDocsByType(index, type) {

        //     console.log("Index: ", index, "type: ", type)

        //     return this.dataset.data.items[index].files.filter((x) => x.type == type).map((x) => x.name).join("; ");
        // },

        // hasDocument(index, step) {
        //     const item = this.dataset.data.items[index];

        //     // const fieldName = `file${step}`;
        //     // const fileUploaded = item[fieldName] && item[fieldName].length > 0;

        //     let documentSelected = false;

        //     if (step === 1) {
        //         documentSelected = !!item.type;
        //     }
        //     if (step === 2) {
        //         documentSelected = this.selectedPetitionDocument != null;
        //     }
        //     if (step === 3) {
        //         documentSelected = this.selectedProcurationDocument != null;
        //     }
        //     if (step === 4) {
        //         documentSelected = this.selectedSpreadsheet != null;
        //     }
        //     if (step === 5) {
        //         return this.selectSuportingDocument.length > 0;
        //     }
        //     if (step === 6) {
        //         return this.selectOtherDocuments.length > 0;
        //     }

        //     return documentSelected;
        // },

        hasDocument(index, type, required) {
            if (!required) return true;

            const items = this.dataset.data.items[index].files.filter((x) => x.type == type);

            return items.length > 0;
        },



        onCancel(index, step) {
            if (step > 1) {
                this.dataset.data.items[index].step = step - 1;
            }

            switch (step) {
                case 2:
                    this.selectedPetitionDocument = null;
                    this.selectedProcurationDocument = null;
                    this.dataset.data.items.file1 = null;
                    this.dataset.data.items.file2 = null;


                    break;
                case 3:
                    this.selectedProcurationDocument = null;
                    this.selectedSpreadsheet = null;
                    this.dataset.data.items.file2 = null;
                    this.dataset.data.items.file3 = null;
                    break;
                case 4:
                    this.selectedSpreadsheet = null;
                    this.selectSuportingDocument = [];
                    this.dataset.data.items.file3 = null;
                    this.dataset.data.items.file4 = [];

                    break;
                case 5:
                    this.selectSuportingDocument = [];
                    this.selectOtherDocuments = [];
                    this.dataset.data.items.file4 = [];
                    this.dataset.data.items.file5 = [];

                    break;
            }

        },

        getItemNumber(i) {
            return i + 1;
        },

        onInput(index, step) {
            if (!this.dataset.data.items[index].step) this.dataset.data.items[index].step = 1;

            if (this.hasDocument(index, step)) {
                this.dataset.data.items[index].step = step + 1;
            } else {
                this.$dialog.notify.error('Selecione um documento antes de continuar', {
                    position: 'top-right',
                    timeout: 2000
                });
            }
        },

        async loadText() {
            try {
                const result = await axios.get(`/api/dynamic-infos/keys`, {
                    params: { keys: 'create-user-event-anonymous,create-user-event-logged' }
                });

                if (!result.data.content) return;

                let contentUserAnonymous = result.data.content.find(
                    (x) => x.key === 'create-user-event-anonymous'
                );
                let contentUserLogged = result.data.content.find(
                    (x) => x.key === 'create-user-event-logged'
                );

                this.dataset.contentUserAnonymous = contentUserAnonymous
                    ? contentUserAnonymous.value
                    : '';
                this.dataset.contentUserLogged = contentUserLogged ? contentUserLogged.value : '';
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                });
            }
        },

        async loadClients() {
            try {
                const result = await axios.get(`/api/clients`, { params: { limit: 100000 } });

                if (!result.data.content.data) return;

                this.dataset.clients = result.data.content.data;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                });
            }
        },

        async loadUserInfo() {
            this.dataset.loading = true;

            try {
                const result = await axios.get('/api/users/info');

                this.user = result.data.content;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 5000
                });
            } finally {
                this.dataset.loading = false;
            }
        },

        async load() {
            this.dataset.loading = true;

            try {
                const result = await axios.get('/api/user-events/request');

                this.data = result.data.content;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 5000
                });
            } finally {
                this.dataset.loading = false;
            }
        },

        // onFileSelected(files, step, index) {
        //     this.dataset.data.items[index][`file${step}`] = files;
        // },

        // validateFormItems() {
        //     if (!this.dataset.data.items.length) return false;

        //     for (let item of this.dataset.data.items) {
        //         if (!item.origin || !item.amount || !item.currency) {
        //             return false;
        //         }

        //         if (!item.file1 || !item.file2 || !item.file3) {
        //             return false;
        //         }

        //         if ((Array.isArray(item.file4) && item.file4.length === 0) ||
        //             (Array.isArray(item.file5) && item.file5.length === 0)) {
        //             return false;
        //         }
        //     }
        //     return true;
        // },



        validateFormItems() {
            if (!this.dataset.data.items.length) return false;

            for (let item of this.dataset.data.items) {
                if (!item.origin || !item.amount || !item.currency) {
                    return false;
                }

                if (!item.type || (Array.isArray(item.file4) && item.file4.length === 0)) {
                    return false;
                }
            }
            return true;
        },


        async save() {

            if (!this.$refs.form.validate()) {

                this.$dialog.notify.error(
                    'Preencha as informações obrigatórias de um negócio antes de continuar',
                    {
                        position: 'top-right',
                        timeout: 2000
                    }
                );
                return;
            }


            if (!this.dataset.data.items.length) {
                this.$dialog.notify.error(
                    'Preencha as informações de um negócio antes de continuar',
                    {
                        position: 'top-right',
                        timeout: 2000
                    }
                );
                return;
            }

            if (!this.validateFormItems()) {
                this.$dialog.notify.error('Preencha todos os campos antes de continuar', {
                    position: 'top-right',
                    timeout: 2000
                });
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja concluir o formulário?',
                title: 'Finalizar',
                actions: {
                    false: 'Cancelar',
                    true: 'Concluir'
                }
            });

            if (!proceedAction) return;

            this.dataset.loading = true;

            const payload = this.buildPayload();

            try {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const response = await axios.post('/api/user-events/request', payload);

                await this.$dialog.confirm({
                    text: 'Informações enviadas com sucesso! Você receberá um e-mail assim que sua solicitação for analisada.',
                    title: 'Sucesso',
                    actions: {
                        true: 'OK'
                    }
                });

                this.dataset.data = { items: [] };

                this.$router.replace('/habilitacoes-divergencias');
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                });
            } finally {
                this.dataset.loading = false;
            }
        },


        buildPayload() {
            let payload = {
                client_id: this.dataset.data.client,
                items: []
            };

            for (let item of this.dataset.data.items) {

                let itemData = {
                    currency: item.currency,
                    origin: item.origin,
                    amount: item.amount,
                    observation: item.observation,
                    type: item.type,
                    files: []
                };

                for (let file of item.files) {
                    itemData.files.push(file.id);
                }

                payload.items.push(itemData);
            }

            return payload

        },

        // buildPayload() {
        //     const formData = new FormData();

        //     formData.append('client_id', this.dataset.data.client);

        //     const transformedItems = this.dataset.data.items.map(item => {
        //         const files = [];

        //         if (item.file1) files.push(item.file1);
        //         if (item.file2) files.push(item.file2);
        //         if (item.file3) files.push(item.file3);
        //         if (item.file4) files.push(item.file4);

        //         if (Array.isArray(item.file5)) {
        //             item.file5.forEach(fileId => {
        //                 files.push(fileId);
        //             });
        //         }

        //         return {
        //             currency: item.currency,
        //             origin: item.origin,
        //             amount: item.amount,
        //             observation: item.observation,
        //             files
        //         };
        //     });

        //     formData.append('items', JSON.stringify(transformedItems));

        //     return formData;
        // },




        // buildPayload() {
        //     const formData = new FormData();

        //     formData.append('client_id', this.dataset.data.client);

        //     //TODO: Melhorar lógica de atribuição dos documentos

        //     for (let item of this.dataset.data.items) {
        //         const refFiles = [];

        //         for (let file of item.file1) {
        //             const key = uuidv4();
        //             refFiles.push({ key, type: 1 });
        //             formData.append('files[]', file, key);
        //         }

        //         for (let file of item.file2) {
        //            
        //         }

        //         for (let file of item.file3) {
        //             const key = uuidv4();
        //             refFiles.push({ key, type: 3 });
        //             formData.append('files[]', file, key);
        //         }

        //         for (let file of item.file4) {
        //             const key = uuidv4();
        //             refFiles.push({ key, type: 4 });
        //             formData.append('files[]', file, key);
        //         }
        //         for (let file of item.file5) {
        //             const key = uuidv4();
        //             refFiles.push({ key, type: 5 });
        //             formData.append('files[]', file, key);
        //         }


        //         item.refFiles = refFiles;
        //     }

        //     formData.append('items', JSON.stringify(this.dataset.data.items));

        //     console.log(formData)

        //     return formData;
        // },

        addItem() {
            this.dataset.data.items.push({
                currency: 1,
                step: 1,
                files: []
            });
        },

        removeItem(index) {
            this.dataset.data.items.splice(index, 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },


        // UP DOC

        confirmSelection() {

            const selectedDocs = this.documentsCenter.filter(doc => doc.isSelected);

            console.log('selected docs ', selectedDocs);

            const files = this.dataset.data.items[this.dataset.selectedEventItem].files;

            this.dataset.data.items[this.dataset.selectedEventItem].files = files.concat(selectedDocs);

            this.closeModal();
        },

        closeModal() {
            this.showDocumentModal = false;
            this.newFile = null;
            this.documentsCenter.forEach(doc => doc.selected = false);
        },

        closeAddModalDocument() {
            this.showAddDocumentModal = false;
        },

        openAddModalDocument(docTypeId) {
            this.currentDocTypeId = docTypeId;
            this.currentDocTypeName = this.getDocTypeName(docTypeId);
            this.showAddDocumentModal = true;
        },

        openDocumentModal(index, type) {
            this.currentDocType = type;

            this.dataset.selectedEventItem = index;

            this.currentDocTypeId = type;

            this.dataset.selectedEventItem = index;

            this.loadingDocuments = true;

            this.getDocuments(type).finally(() => {
                this.loadingDocuments = false;
                this.showDocumentModal = true;
            });
        },


        async getDocuments(docTypeId) {

            this.loadingDocuments = true;

            try {
                const response = await axios.get(`/api/user-docs`, {
                    params: { start: 0, limit: 1000, search: { "type": docTypeId } }
                });

                if (response.data && response.data.status && response.data.content) {

                    this.documentsCenter = response.data.content.data.map(doc => {

                        const docType = this.dataset.itemTypes.find(type => type.id === doc.type);

                        return {
                            isSelected: false,
                            name: doc.name,
                            // type: docType ? docType.name : '',
                            type: doc.type,
                            created_at: doc.created_at,
                            file_url: doc.file_url,
                            id: doc.id

                        };
                    }).sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    });
                }


            } catch (error) {
                console.error("Erro ao obter documentos", error);
            } finally {
                this.loadingDocuments = false;
            }
        },

        async saveNewDocument() {
            if (!this.$refs.formNewDocument.validate()) return;

            this.dataset.loading = true;

            try {
                let formData = new FormData();

                formData.append('name', this.newDocument.name);
                formData.append('type', this.currentDocTypeId);
                formData.append('file', this.newDocument.file);

                const response = await axios.post('/api/user-docs', formData);

                await this.getDocuments(this.currentDocTypeId);
                this.closeAddModalDocument();

                this.newDocument = [];

                this.$dialog.notify.success("Documento salvo com sucesso", { position: 'top-right', timeout: 5000 });
            } catch (error) {
                this.$dialog.notify.error("Erro ao salvar o documento", { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },
    },


};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: 'Raleway';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    font-family: 'Raleway';
    font-size: 14px;
    font-style: normal;
}

.custom-modal-title {
    font-size: 20px;

    @media (max-width: 600px) {
        font-size: 14px;
    }
}
</style>
