















































































import Vue from "vue";
import officeAddress from "@/common/data/office-address.data";

export default Vue.extend({
    name: "App",

    components: {
        //Home
    },

    data: () => ({

        officeAddress: officeAddress
    }),

    methods: {
        open: function () {
            this.$data.drawer = !this.$data.drawer;
        },
        openSocialLink(link) {
            window.open(link, '_blank');
        }
    },


    computed: {
        hide: function () {
            return this.$route.path === "/login" || this.$route.path === "/register" || /^\/requests\/.*\/view$/.test(this.$route.path);
        },

        cssProps() {
            var themeColors = {}
            Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
                themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
            })
            return themeColors
        }
    },
    created: function () {
        //$root.user = parse(localStorage.getItem('user'));
    }
});
