<template>
  <section class="limiter-content">
    <div class="padding-block"></div>

    <v-row justify="center">
      <v-col lg="12">
        <h1 class="section-page-title">
          <span class="primary--text">fale</span><br><span
            class="section-page-title-underline darkSecondary--text"><strong>conosco</strong></span>
        </h1>
      </v-col>
    </v-row>

    <br />

    <div v-html="dataset.description"></div>

    <!-- <v-row justify="end">
      <v-col lg="10">
        <v-form @submit.prevent="submitForm">
          <v-row justify="end" class="mx-2">
            <v-col cols="12" lg="12" class="formFields">
              <v-text-field
                v-model="formData.nome"
                label="Nome"
                outlined
                dense
                required
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" class="formFields">
              <v-text-field
                v-model="formData.email"
                label="E-mail"
                outlined
                dense
                required
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" class="formFields">
              <v-text-field
                v-model="formData.telefone"
                label="Telefone"
                outlined
                dense
                required
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" class="formFields">
              <v-text-field
                v-model="formData.cidade"
                label="Cidade"
                outlined
                dense
                required
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" class="formFields">
              <v-text-field
                v-model="formData.UF"
                label="UF"
                outlined
                dense
                required
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="12" class="formFields">
              <v-textarea
                v-model="formData.mensagem"
                label="Mensagem"
                outlined
                required
                :rules="[$rules.required]"
                rows="4"
              ></v-textarea>
            </v-col>

            <v-col class=" text-uppercase text-right">
              <v-btn type="submit" class="sendButton-contact" data-attach-loading>
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row> -->

    <v-row>
      <whatsButton />
    </v-row>

    <v-row>
      <topButton />
    </v-row>

  </section>
</template>

<script>
import 'moment/locale/pt-br';
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';

import axios from "axios";

export default {
  components: {
    topButton,
    whatsButton,
  },
  data() {
    return {
      /*
      formData: {
        nome: '',
        email: '',
        telefone: '',
        cidade: '',
        UF: '',
        mensagem: ''
      }, 
      */
      dataset: {
        description: ''
      }

    }
  },

  mounted: function () { },

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'contact'
    })

    this.loadText();
  },

  methods: {
    /*
    submitForm() {
      console.log('Valores do formulário:', this.formData)
    },

    clearForm() {
      this.formData = {
        nome: '',
        email: '',
        telefone: '',
        cidade: '',
        UF: '',
        mensagem: ''
      }
    },
    */
    async loadText() {
      try {
        const result = await axios.get(`/api/dynamic-infos/keys`, {
          params: { keys: 'contact-description' }
        })

        this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      }
    }
  }
}
</script>

<style lang="scss">
.title-page {
  color: var(--v-primary-base);
  font-family: 'Raleway' !important;
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

p {
  text-align: justify;
}

.formFields {
  width: 100%;
  margin-bottom: -30px;
}

.formContact {
  width: 75%;
  margin-right: -15px;
  margin-left: 15px;
}

.sendButton-contact {
  margin-right: 0px !important;
  margin-top: 1px !important;
  font-size: 18px !important;
  width: 150px;
  height: 50px !important;
  color: white !important;
  background-color: #009986 !important;
  transition: background-color 0.3s ease;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.sendButton:hover {
  background-color: #0077cc !important;
}
</style>
