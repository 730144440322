<template>
  <div @click="handleClick(id)" class="no-underline justify-center mt-16 align-center text-center clickable-element">
    <div class="image-container">
      <v-img v-if="imageUrl" contain height="90" :src="imageUrl" style="cursor: pointer;"></v-img>
      <span class="client-name-card" style="cursor: pointer;" v-else>
        {{ name }}
      </span>
    </div>

    <p class="black--text font-weight-medium text-center">Passivo: R$ {{ passivo | currency }}</p>
    <p class="black--text font-weight-medium text-center">Credores: {{ credores }}</p>


  </div>
</template>

<script>
export default {
  name: 'CompanyCardHomeProcesses',

  props: {
    id: Number,
    linkHref: String,
    imageUrl: String,
    passivo: Number,
    credores: Number,
    name: String
  },
  methods: {

    async handleClick(id) {

      await this.$router.push({ name: 'CasesDetails', params: { id } });

      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });

    }
  }
}
</script>

<style>
 .image-container::after {
  content: '';
  display: block;
  margin: 10px auto;
  height: 3px;
  width: 180px;
  background: var(--v-primary-base);
  justify-content: flex-end;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; 
  height: 130px; 
}

.client-name-card {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  height: 30px; 
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-underline {
  text-decoration: none !important;
  font-family: 'Metropolis', sans-serif;
}
</style>
