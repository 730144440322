<template>
  <div class="limiter-content pa-3 pa-lg-0">
    <div class="padding-block"></div>

    <v-row no-gutters justify="center" class='mb-5'>
      <v-col lg="12">
        <h1 class="custom-title-page  font-weight-bold">
          ÁREA DE <span class="underline primary--text">CREDENCIAMENTO</span>
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <span v-if="dataset.generalMeeting">
          Você está realizando o credenciamento para a Assembleia <strong>{{ dataset.generalMeeting.name }}</strong>.
        </span>
      </v-col>
    </v-row>

    <v-form v-if='user' ref="form" v-model="valid" class='mt-10'>

      <template>
        <v-stepper v-model="e1" vertical>
          <v-stepper-step :complete="e1 > 1" step="1">
            <v-row no-gutters justify='center' align='center'>
              <v-col cols='11'>
                <h4>Participação</h4>
                <small>Selecione a participação</small>
              </v-col>

              <v-col cols='1'>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small :color="e1 === 1 ? 'primary' : ''" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <span>Informações sobre a participacao</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-form ref="form" class='px-3' v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="participationType">
                    <v-radio label="Participar em nome próprio" value="own"></v-radio>
                    <v-radio label="Participar por representação (advogado)" value="representation" :disabled="isRepresentationDisabled()"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-btn @click="e1 = 2" :disabled="!participationType" class="primary">Continuar</v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-step :complete="e1 > 2" step="2">
            <v-row no-gutters justify='center' align='center'>
              <v-col cols='11'>
                <h4>Informações</h4>
                <small>Selecione as informações abaixo</small>
              </v-col>

              <v-col cols='1'>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small :color="e1 === 2 ? 'primary' : ''" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <span>Informações sobre a participacao</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-row no-gutters>
              <v-col>
                <v-text-field prepend-icon="mdi-paperclip" readonly outlined class='mt-1' dense
                  :value="files.idCredor ? files.idCredor.name : ''" label="Documento de identificação do credor"
                  @click.prevent="openDocumentModal('idCredor', 1)" :rules="[$rules.required]">
                </v-text-field>
              </v-col>
              <v-col sm="1"></v-col>
              <v-col>
                <v-text-field v-if="participationType === 'representation'" readonly prepend-icon="mdi-paperclip" outlined
                  class='mt-1' dense :value="files.procuracao ? files.procuracao.name : ''" label="Procuração"
                  @click.prevent="openDocumentModal('procuracao', 2)" :rules="[$rules.required]">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <v-text-field v-if="participationType === 'representation'" prepend-icon="" outlined class='mt-1' dense
                  v-model="dataset.data.procurator_name" label="Nome do procurador" :rules="[$rules.required]">
                </v-text-field>
              </v-col>
              <v-col sm="1"></v-col>
              <v-col>
                <v-text-field v-if="participationType === 'representation'" prepend-icon="" outlined class='mt-1' dense
                  v-model="dataset.data.procurator_registration" label="OAB" :rules="[$rules.required]">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <v-text-field v-if="participationType === 'representation'" prepend-icon="" outlined class='mt-1' dense
                  v-model="dataset.data.procurator_email" label="E-mail do procurador"
                  :rules="[$rules.required, $rules.email]">
                </v-text-field>
              </v-col>
            </v-row>

            <v-btn @click="e1 = 3" :disabled="!valid" class="primary">Continuar</v-btn>
            <v-btn text @click="e1 = 1; clearFormFields()" class="ml-3">Voltar</v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e1 > 3" step="3">
            <v-row no-gutters justify='center' align='center'>
              <v-col cols='11'>
                <h4>Confirmação</h4>
                <small>Revise e confirme</small>
              </v-col>

              <v-col cols='1'>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small :color="e1 === 3 ? 'primary' : ''" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <span>Faça a revisão e envie os documentos</span>
                </v-tooltip>
              </v-col>
            </v-row>

          </v-stepper-step>

          <v-stepper-content step="3">
            <v-row>
              <v-col cols="12">
                <div class='py-3'>
                  <p v-if="participationType === 'own'">
                    Você está participando em nome próprio e enviou o documento: <br> {{ files.idCredor ?
                      files.idCredor.name : 'Nenhum documento enviado' }}.
                  </p>
                  <p v-else>
                    Você está participando por representação e enviou os seguintes documentos:
                    <br>
                    <br>
                    <strong>Documento de Identificação: </strong>
                    {{ files.idCredor ? files.idCredor.name : 'Nenhum' }}
                    <br>
                    <strong>Procuração: </strong>
                    {{ files.procuracao ? files.procuracao.name : 'Nenhum' }}
                    <br>
                    <strong>Procurador:</strong>
                    {{ dataset.data.procurator_name }}
                    <br>
                    <strong>E-mail do procurador:</strong>
                    {{ dataset.data.procurator_email }}
                    <br>
                    <strong>OAB do procurador:</strong>
                    {{ dataset.data.procurator_registration }}.
                  </p>
                </div>
                <v-btn @click="save" :disabled="!valid" class="primary">Confirmar e Enviar</v-btn>
                <!-- <v-btn @click="cancelSubmission" text class="ml-3">Cancelar</v-btn> -->
                <v-btn text @click="e1 = 2; clearFormFields()" class="ml-3">Voltar</v-btn>

              </v-col>
            </v-row>
          </v-stepper-content>

        </v-stepper>
      </template>

    </v-form>

    <v-dialog v-model="showDocumentModal" max-width="800px">
      <v-card>
        <v-card-title>Escolha um Documento</v-card-title>
        <v-card-text>
          <!-- <v-btn class='mb-3' @click="showAddDocumentModal = true" color="primary">
            Novo documento
          </v-btn> -->
          <v-btn class='mb-3' @click="openAddModalDocument(currentDocTypeId)" color="primary">
            Novo documento
          </v-btn>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>Selecionar da Central de Documentos</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list dense v-if="centralDocuments.length">
                  <v-list-item v-for="(doc, index) in centralDocuments" :key="index">
                    <v-list-item-action>
                      <v-checkbox v-model="doc.isSelected" :disabled="!doc.isSelected && isAnyDocumentSelected">
                      </v-checkbox>

                    </v-list-item-action>
                    <v-list-item-content>{{ doc.name }}</v-list-item-content>
                    <v-list-item-content>{{ doc.type }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-card-actions class='pa-0 mt-4'>
            <v-btn color="primary" @click="closeModal">Fechar</v-btn>
            <v-btn color="secondary" @click="confirmSelection">Confirmar</v-btn>
          </v-card-actions>
        </v-card-text>

      </v-card>
    </v-dialog>

    <v-dialog v-model="showAddDocumentModal" max-width="800px">
      <v-card>
        <v-card-title>Adicione um novo documento</v-card-title>

        <v-form ref="form" v-model="valid">
          <v-card-text class='mt-1'>
            <v-text-field outlined dense required :rules="[$rules.required]" v-model="newDocument.name"
              label="Nome"></v-text-field>

            <!-- <v-select :items="dataset.itemTypes" :rules="[$rules.required]" item-text="name" item-value="id"
              v-model="newDocument.type" label="Tipo" outlined dense required></v-select> -->

            <v-text-field outlined dense readonly :value="getDocTypeName(currentDocTypeId)"
              label="Tipo de Documento"></v-text-field>


            <v-file-input outlined dense required prepend-icon="" :rules="[$rules.required]" v-model='newDocument.file'
              label="Upload do Documento">
            </v-file-input>

          </v-card-text>

          <v-col cols='12' sm='3'>
            <v-btn @click="saveNewDocument" color="success" block :disabled="savingDocument">
              Enviar
              <v-progress-circular v-if="savingDocument" indeterminate color="white" size="20"
                class="ml-2"></v-progress-circular>
            </v-btn>
          </v-col>

        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showInfoModal" max-width="500px" persistent>
      <v-card class="elevation-3">
        <v-card-title class="blue darken-1 white--text pa-3">
          <v-row align="center" justify="center" class="text-center">
            <v-col cols="auto" class="d-flex align-center">
              <span class="text-h6 font-weight-medium">Aviso</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <br>
        <v-card-text class="body-1 text-center">
          O prazo para credenciamento por <strong>representante</strong> para a assembléia <strong>{{ dataset.generalMeeting.name }}</strong> expirou!
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pa-3">
          <v-btn color="primary" @click="closeInfoModal">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { eventHub } from '@/main';

import axios from 'axios';
import itemTypes from "@/common/data/item-types.data.js";


export default {
  name: 'AccreditationPage',

  data() {
    return {
      user: null,
      currentDocTypeId: null,
      valid: true,
      panel: [0],
      savingDocument: false,
      e1: 1,
      participationType: '',
      clientId: null,
      meetingInfo: null,
      files: {
        idCredor: null,
        procuracao: null
      },
      newDocument: {},
      showDocumentModal: false,
      showAddDocumentModal: false,
      showInfoModal: false,
      newFile: null,
      centralDocuments: [],
      currentDocType: null,
      dataset: {
        itemTypes: itemTypes,
        loading: false,
        generalMeeting: {},
        data: {}
      },
    };
  },

  created() {
    this.user = this.$root.user;

    if (!this.$route.query.id)
      this.$router.push(`/assembleia-credores`)

    this.loadGeneralMeeting();

  },

  computed: {
    idCredorName() {
      const doc = this.centralDocuments.find(d => d.id === this.files.idCredor);
      return doc ? doc.type : '';
    },

    procuracaoName() {
      const doc = this.centralDocuments.find(d => d.id === this.files.procuracao);
      return doc ? doc.name : '';
    },

    isAnyDocumentSelected() {
      return this.centralDocuments.some(doc => doc.isSelected);
    }
  },


  methods: {

    openAddModalDocument(docTypeId) {
      event.preventDefault();
      this.currentDocTypeId = docTypeId;
      this.currentDocTypeName = this.getDocTypeName(docTypeId);
      this.showAddDocumentModal = true;
    },

    getDocTypeName(docTypeId) {
      let docType = this.dataset.itemTypes.find(type => type.id === docTypeId);
      return docType ? docType.name : 'Tipo Desconhecido';
    },

    isRepresentationDisabled() {
      return this.dataset.generalMeeting.hoursDiff <= 0;
    },

    closeInfoModal() {
      this.showInfoModal = false;
    },

    loadInfoModal(){
      if (this.dataset.generalMeeting.hoursDiff <= 0) {
        this.showInfoModal = true;
      }
    },
    
    async loadGeneralMeeting() {
      try {

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        //const response = await axios.get(`/api/general-meetings/${this.$route.query.id}`);

        const response = await axios.get(`/api/general-meetings/${this.$route.query.id}`, {
          headers: {
            'Timezone': timezone
          }
        });

        this.dataset.generalMeeting = response.data.content;

        this.loadInfoModal();

      } catch (error) {
        console.error('Erro ao obter informações do meeting', error);
      }
    },

    onFileChange(fileType, event) {
      if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
        console.error('Nenhum arquivo foi selecionado ou houve um problema no evento de seleção de arquivo');
        return;
      }

      this.files[fileType] = event.target.files[0];
    },

    async submitForm() {
      if (this.e1 !== 3) return;

      if (!this.$refs.form.validate()) return;

      // if (idCredorFile) {

      //   await this.$dialog.notify.success('Documentos enviados com sucesso!', {
      //     position: 'top-right',
      //     timeout: 5000
      //   });

      //   console.log('Documento de identificação do credor:', idCredorFile.name, idCredorFile);
      //   this.clearFormFields();
      // } else {
      //   console.log('Nenhum documento de identificação do credor selecionado');
      // }

      const payload = this.buildPayload()

      // if (this.participationType === 'representation' && procuracaoFile) {
      //   console.log('Procuração:', procuracaoFile.name, procuracaoFile);
      // } else if (this.participationType === 'representation') {
      //   console.log('Nenhum documento de procuração selecionado');
      // }
    },

    async save() {
      if (this.e1 !== 3) return;

      if (!this.$refs.form.validate()) return;

      const proceedAction = await this.$dialog.confirm({
        text: 'Deseja enviar o credenciamento?',
        title: 'Finalizar',
        actions: {
          false: 'Cancelar',
          true: 'Concluir'
        }
      });

      if (!proceedAction) return;

      await this.loadGeneralMeeting();
      
      if (this.participationType === 'representation' && this.dataset.generalMeeting.hoursDiff <= 0){
        
        this.$dialog.notify.error("O prazo para credenciamento por representação expirou!", {
          position: 'top-right',
          timeout: 5000
        });
       
        return;

      }

      this.dataset.loading = true;

      const payload = this.buildPayload();

      try {
        const response = await axios.post('/api/accreditations', payload);

        await this.$dialog.confirm({
          text: 'Informações enviadas com sucesso! Você receberá um e-mail assim que o seu credenciamento for analisado.',
          title: 'Sucesso',
          actions: {
            true: 'OK'
          }
        });

        this.dataset.data = {};

        this.$router.replace('/assembleia-credores');

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        });
      } finally {
        this.dataset.loading = false;
      }
    },

    buildPayload() {
      const result = {
        has_procurator: this.participationType == 'representation',
        general_meeting_id: this.$route.query.id,
        files: [this.files.idCredor.id]
      };

      if (this.participationType == 'representation') {
        result.procurator_email = this.dataset.data.procurator_email;
        result.procurator_name = this.dataset.data.procurator_name;
        result.procurator_registration = this.dataset.data.procurator_registration;
        result.files.push(this.files.procuracao.id)
      }

      return result;
    },

    cancelSubmission() {
      this.e1 = 1;
      this.clearFormFields();
    },

    clearFormFields() {
      this.files.idCredor = null;
      this.files.procuracao = null;

      if (this.participationType === 'representation') {
        this.dataset.data.procurator_name = '';
        this.dataset.data.procurator_registration = '';
        this.dataset.data.procurator_email = '';
      }
    },

    openDocumentModal(docType, docTypeId) {
      this.currentDocType = docType;
      this.currentDocTypeId = docTypeId;
      this.loadingDocuments = true;
      this.getDocuments(docTypeId).finally(() => {
        this.loadingDocuments = false;
        this.showDocumentModal = true;
      });
    },


    confirmSelection() {
      const selectedDoc = this.centralDocuments.find(doc => doc.isSelected);

      if (selectedDoc) {
        if (this.currentDocType === 'idCredor') {
          this.files.idCredor = selectedDoc;
        } else if (this.currentDocType === 'procuracao') {
          this.files.procuracao = selectedDoc;
        }
      }

      this.closeModal();
    },


    closeModal() {
      this.showDocumentModal = false;
      this.centralDocuments.forEach(doc => doc.isSelected = false);
    },


    closeAddModalDocument() {
      this.showAddDocumentModal = false;
    },

    async getDocuments(docTypeId) {
      try {
        const response = await axios.get(`/api/user-docs`, {
          params: { start: 0, limit: 1000, search: { "type": docTypeId } }
        });

        if (response.data && response.data.status && response.data.content) {

          this.centralDocuments = response.data.content.data.map(doc => {

            const docType = this.dataset.itemTypes.find(type => type.id === doc.type);

            return {
              isSelected: false,
              name: doc.name,
              type: docType ? docType.name : '',
              created_at: doc.created_at,
              file_url: doc.file_url,
              id: doc.id
            };
          }).sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
        }


      } catch (error) {
        console.error("Erro ao obter documentos", error);
      }
    },

    async saveNewDocument() {
      if (!this.$refs.form.validate()) return;
      this.savingDocument = true;

      try {
        let formData = new FormData();

        formData.append('name', this.newDocument.name);
        formData.append('type', this.currentDocTypeId);
        formData.append('file', this.newDocument.file);

        const response = await axios.post('/api/user-docs', formData);
        if (response.data && response.data.document) {
          if (this.currentDocType === 'idCredor') {
            this.files.idCredor = response.data.document;
          } else if (this.currentDocType === 'procuracao') {
            this.files.procuracao = response.data.document;
          }
        }

        this.getDocuments(this.currentDocTypeId);
        this.closeAddModalDocument();

        this.$dialog.notify.success("Documento salvo com sucesso", { position: 'top-right', timeout: 5000 });
      } catch (error) {
        this.$dialog.notify.error("Erro ao salvar o documento", { position: 'top-right', timeout: 5000 });
      } finally {
        this.savingDocument = false;

      }
    },

  }
};
</script>


<style>
.custom-modal-title {
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}
</style>

