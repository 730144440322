
<template>
    <v-card class="news-card" :to="{ name: 'NewsDetails', params: { id: news.id } }">

        <div class="thumbnail-container">
            <v-img lazy-src="https://picsum.photos/id/11/10/6" :height="240" :src="news.cover_image_url"
                class="news-thumbnail"></v-img>
        </div>

        <v-card-title class="text-uppercase title">{{ news.title }}</v-card-title>
        <v-card-text>
            <p>Postado em <strong>{{ news.created_at | defaultDate('DD/MM/YYYY') }}</strong></p>
            <div v-html="truncateText(news.content, 100)"></div>
        </v-card-text>

        <v-card-actions>
            <v-btn class="read-more-button font-weight-bold" color="secondary">Leia mais</v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>

export default {
    props: {
        news: Object,
    },
    methods: {

        truncateText(text, maxLength) {
            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        },
    },
};

</script>
  

<style scoped>
.title {
    word-break: break-word;
}

.news-card {
    width: 300px;
    height: 640px !important;
    transition: transform 0.3s;
}

.news-thumbnail {
    width: 100%;
    /* object-fit: cover; */
}

.news-thumbnail:hover {
    /* transform: scale(3.2);
    object-fit: cover;
    background-size: contain; */

}

.button {
    font-family: "Metropolis", sans-serif;
    font-size: 16px;
    width: 150px;
    height: 40px;
    color: white;
    background-color: #009986;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: var(--v-primary-base);
}

.read-more-button {
    margin-right: 0px !important;
    margin-top: 1px !important;
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-family: "Metropolis", sans-serif;
    font-size: 12px !important;
    width: 150px !important;
    height: 40px !important;
    color: white !important;
    background-color: var(--v-secondary-base) !important;
    transition: background-color 0.3s ease;
}

.read-more-button:hover {
    background-color: #0077cc !important;
}
</style>
  
