<template>
    <div class="limiter-content pa-3 pa-lg-0">
        <div class="padding-block"></div>

        <v-row no-gutters justify="center">
            <v-col lg="12">
                <h1 class="section-page-title">
                    <span class="primary--text">central</span><br><span
                        class="section-page-title-underline darkSecondary--text"><strong>de documentos</strong></span>
                </h1>
            </v-col>
        </v-row>

        <v-card v-if="user">
            <v-text-field v-model="search" append-icon="mdi-magnify" outlined
                label="Pesquise o documento pelo nome ou tipo" class='mt-10 pa-2' hide-details dense></v-text-field>
            <v-data-table :headers="headers" :items="filteredDocuments()" :items-per-page="5" class="elevation-1 mt-2">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.name }}</td>
                        <td>{{ item.type }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ formatarData(item.created_at) }}</td>
                        <td>
                            <v-btn title='' @click="viewDocument(item)" icon>
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                            <v-btn @click="deleteDocument(item)" icon>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
                <template v-slot:footer>
                    <tr>
                        <td colspan="5" class="text-center">
                            <v-btn @click="addRow" class="ma-4" color="primary">Adicionar</v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="modalAdd" max-width="800px">
            <v-card class='pa-3'>
                <h1 class="pa-2 custom-modal-title font-weight-bold">
                    ADICIONAR<span class="underline primary--text"> DOCUMENTO</span>
                </h1>
                <v-form ref="form" v-model="valid">
                    <v-card-text class='mt-1'>
                        <v-text-field outlined dense required :rules="[$rules.required]" v-model="newDocument.name"
                            label="Nome"></v-text-field>

                        <v-select :items="dataset.itemTypes" :rules="[$rules.required]" item-text="name" item-value="id"
                            v-model="newDocument.type" label="Tipo" outlined dense required></v-select>

                        <v-file-input outlined dense required prepend-icon="" :rules="[$rules.required]"
                            v-model='newDocument.file' label="Upload do Documento">
                        </v-file-input>

                        <v-expansion-panels>
                            <v-expansion-panel v-if="pendingDocuments.length > 0">
                                <v-expansion-panel-header class='font-weight-bold'>Documentos
                                    Adicionados</v-expansion-panel-header>
                                <v-divider></v-divider>

                                <v-expansion-panel-content>
                                    <v-list dense>
                                        <v-list-item v-for="(doc, index) in pendingDocuments" :key="index">

                                            <v-list-item-content>
                                                <v-list-item-title>{{ doc.name }}</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-content class='ml-3'>
                                                <v-list-item-title>{{ doc.typeName }}</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-btn icon @click="removePendingDocument(index)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-btn @click="closeModalAdd" color="error" block>Cancelar</v-btn>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-btn @click="addDocumentToList" color="primary" block>Adicionar à
                                    lista</v-btn>
                            </v-col>
                            <v-col cols="12" sm="4" v-if="pendingDocuments.length > 0">
                                <v-btn @click="saveAllDocuments" color="success" block :disabled="savingDocuments">
                                    Salvar Todos
                                    <v-progress-circular v-if="savingDocuments" indeterminate color="white" size="20"
                                        class="ml-2"></v-progress-circular>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmDeleteDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">Confirmar exclusão</v-card-title>
                <v-card-text>Tem certeza de que deseja excluir este documento?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="confirmDeleteDialog = false">Cancelar</v-btn>
                    <v-btn color="red darken-1" text @click="confirmDelete">Excluir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <div class='mt-10' v-if='!user'>
            <h4>Por favor, realize o seu login para ter acesso à Central de Documentos</h4>
        </div>

    </div>
</template>


<script>
import { eventHub } from '@/main';

import axios from 'axios';
import itemTypes from "@/common/data/item-types.data.js";


export default {
    name: 'DocumentCenterPage',

    data() {
        return {
            user: null,
            valid: true,
            search: '',
            headers: [
                { text: 'Nome', },
                { text: 'Tipo', },
                { text: 'Status', },
                { text: 'Data de Criação', },
                { text: 'Ações' }
            ],
            savingDocuments: false,
            documents: [],
            modalAdd: false,
            showModal: false,
            newDocument: {},
            pendingDocuments: [],
            dataset: {
                itemTypes: itemTypes,
                loading: false
            },
            confirmDeleteDialog: false,
            documentToDelete: null,
        };
    },

    created() {
        if (this.$root.user) {
            this.user = this.$root.user;
            this.getDocuments();
        }

    },


    methods: {

        filteredDocuments() {
            if (!this.search) {
                return this.documents;
            }
            const searchLower = this.search.toLowerCase();
            return this.documents.filter(doc =>
                doc.name.toLowerCase().includes(searchLower) ||
                doc.type.toLowerCase().includes(searchLower)
            );
        },

        async getDocuments() {
            try {
                const response = await axios.get('/api/user-docs', {
                    params: { start: 1, limit: 100000, search: {} }
                });


                if (response.data && response.data.status && response.data.content) {
                    this.documents = response.data.content.data.map(doc => {

                        const docType = this.dataset.itemTypes.find(type => type.id === doc.type);

                        return {
                            isSelected: false,
                            name: doc.name,
                            type: docType ? docType.name : '',
                            created_at: doc.created_at,
                            file_url: doc.file_url,
                            id: doc.id,
                            status: doc.status
                        };
                    }).sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    });

                }


            } catch (error) {
                console.error("Erro ao obter documentos", error);
            }
        },

        viewDocument(item) {
            window.open(item.file_url, '_blank');
        },

        addRow() {
            this.modalAdd = true;
        },

        addDocumentToList() {

            const docType = this.dataset.itemTypes.find(type => type.id === this.newDocument.type);

            if (!this.$refs.form.validate()) return;

            this.pendingDocuments.push({
                name: this.newDocument.name,
                type: docType.id,
                typeName: docType.name,
                file: this.newDocument.file
            });

            this.newDocument = { name: '', type: '', file: '' };
            this.$refs.form.reset();
        },

        removePendingDocument(index) {
            this.pendingDocuments.splice(index, 1);
        },

        async saveAllDocuments() {
            this.savingDocuments = true;
            try {
                for (let doc of this.pendingDocuments) {

                    let formData = new FormData();

                    formData.append('name', doc.name);
                    formData.append('type', doc.type);
                    formData.append('file', doc.file);

                    const response = await axios.post('/api/user-docs', formData);

                    // for (let [key, value] of formData.entries()) {
                    //     console.log(key, value);
                    // }

                }

                this.pendingDocuments = [];
                await this.getDocuments();
                this.closeModalAdd();
                this.$dialog.notify.success("Documentos salvos", { position: 'top-right', timeout: 5000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.savingDocuments = false;
            }
        }
        ,


        // async deleteDocument(item) {
        //     try {

        //         console.log("ID RECEBIDO -----> ", item.id)
        //         await axios.delete(`/api/user-docs/${item.id}`);

        //         // this.documents = this.documents.filter(document => document.id !== item.id);y

        //         this.$dialog.notify.success("Documento deletado com sucesso", { position: 'top-right', timeout: 5000 });
        //     } catch (error) {
        //         console.error("Erro ao deletar documento", error);
        //         this.$dialog.notify.error("Erro ao deletar documento", { position: 'top-right', timeout: 5000 });
        //     }
        // },

        deleteDocument(item) {
            this.documentToDelete = item;
            this.confirmDeleteDialog = true;
        },

        async confirmDelete() {
            if (!this.documentToDelete) return;

            try {
                await axios.delete(`/api/user-docs/${this.documentToDelete.id}`);
                this.documents = this.documents.filter(document => document.id !== this.documentToDelete.id);
                this.$dialog.notify.success("Documento deletado com sucesso", { position: 'top-right', timeout: 5000 });
            } catch (error) {
                console.error("Erro ao deletar documento", error);
                this.$dialog.notify.error("Erro ao deletar documento", { position: 'top-right', timeout: 5000 });
            } finally {
                this.confirmDeleteDialog = false;
                this.documentToDelete = null;
            }
        },

        closeModalAdd() {
            this.newDocument = {
                name: '',
                type: '',
                creationDate: null
            };
            this.modalAdd = false;
        },

        formatarData(dataString) {
            const data = new Date(dataString);
            const date = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const hours = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
            return data.toLocaleDateString('pt-BR', date) + ' ' + data.toLocaleTimeString('pt-BR', hours);
        },
    }
}; 
</script>


<style>
.custom-modal-title {
    font-size: 20px;

    @media (max-width: 600px) {
        font-size: 14px;
    }
}
</style>
