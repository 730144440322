<template>
  <section>
    <div class="limiter-content pa-2 pa-lg-0">
      <div class="padding-block"></div>

      <v-row justify="center">
        <v-col lg="12">
          <h1 class="section-page-title">
            <span class="primary--text">liquidação</span><br><span
              class="section-page-title-underline darkSecondary--text"><strong>judicial</strong></span>
          </h1>
        </v-col>
      </v-row>

      <br />

      <div v-html="dataset.description"></div>

      <filter-component :type="3"></filter-component>

      <v-row>
        <whatsButton />
      </v-row>

      <v-row>
        <topButton />
      </v-row>

    </div>
  </section>
</template>

<script>
import 'moment/locale/pt-br'

import { eventHub } from '@/main'

import axios from 'axios'

import FilterComponent from '@/components/cases/FilterComponent.vue';
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';

export default {
  data: () => ({
    user: null,
    dialogPopup: false,
    dataset: {
      description: ''
    },

  }),

  components: {
    FilterComponent,
    topButton,
    whatsButton,
  },

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'home'
    })

    this.loadText()
  },

  methods: {
    openRegister() {
      eventHub.$emit('DIALOG-REGISTER', true)
    },

    async loadText() {
      try {
        const result = await axios.get(`/api/dynamic-infos/keys`, {
          params: { keys: 'judicial-liquidation-description' }
        })

        this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      }
    }
  }
}
</script>

<style lang="scss">
.title-page {
  font-family: 'Raleway';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.underline::after {
  content: '';
  display: block;
  margin: 5px 0;
  height: 3px;
  width: 180px;
  background: var(--v-primary-base);
}

.title-h1 {
  font-size: 40px;
  color: var(--v-mediumGray-base);
}

.text-p {
  font-size: 20px;
  font-family: 'Raleway';
}

.sub-title {
  color: rgb(75, 72, 72);
  font-family: 'Raleway';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

.formFields {
  width: 100%;
  margin-bottom: -30px;
}

.findForm {
  width: 90%;
  margin-right: -15px;
  margin-left: 15px;
}

.findButton {
  margin-right: 0px !important;
  margin-top: 1px !important;
  font-family: Raleway, sans-serif;
  font-size: 30px !important;
  width: 150px !important;
  height: 40px !important;
  color: white !important;
  background-color: #009986 !important;
  transition: background-color 0.3s ease;
}

.findButton:hover {
  background-color: #0077cc !important;
}

.table-spacing tbody tr {
  margin-bottom: 10px;
}

.table-bordered td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.table-bordered tbody tr:first-child td {
  border-top: 1px solid #ddd;
}
</style>
