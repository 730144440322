
<template >
    <section class="limiter-content">
        <div class="padding-block"></div>

        <v-row justify="center" align="center">
            <v-col sm="6">
                <v-btn text class="px-0 custom-title-page-news primary--text font-weight-bold" to="/na-midia">
                    Voltar
                </v-btn>
            </v-col>
            <v-col sm="6" align="right">
                <v-icon color="secondary">mdi-calendar</v-icon>
                {{ dataset.data.created_at | defaultDate('DD/MM/YYYY hh:mm') }}
            </v-col>
        </v-row>

        <br>

        <v-img lazy-src="https://picsum.photos/id/11/10/6" :src="dataset.data.cover_image_url"
            class="news-thumbnail"></v-img>

        <div class="pt-10">
            <span class="news-title text-uppercase">{{ dataset.data.title }}</span>
            <!-- <p> Data: <strong>{{ dataset.data.created_at }}</strong></p>
            <p> Atualização: <strong>{{ dataset.data.updated_at }}</strong> </p> -->
        </div>

        <div class="py-10" v-html="dataset.data.content"></div>

        <v-row>
            <whatsButton />
        </v-row>

        <v-row>
            <topButton />
        </v-row>

    </section>
</template>


<script>

import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';

import axios from "axios";

export default {
    components: {
        topButton,
        whatsButton,
    },

    data() {
        return {
            dataset: {
                data: {}
            }
        };
    },
    methods: {

        getNewsById(id) {

            return this.newsList.find((news) => news.id === parseInt(id, 10));
        },
        goBack() {

            this.$router.push({ name: 'in-media' });
        },

        async load(id) {

            // this.dataList.loading = true;

            try {
                const result = await axios.get(`/api/posts/${id}`);

                this.dataset.data = result.data.content;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                // this.dataList.loading = false;
            }

        },
    },
    created() {

        const id = this.$route.params.id;

        this.load(id);
    },

};

</script>
  
<style scoped>
.news-thumbnail {
    margin: 0 auto;
}

.news-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 auto;
    text-align: justify;
    word-break: break-word;
    color: var(--v-primary-base);

}

.news-content {
    font-size: 16px;
    font-weight: normal;
    height: 75%;
    width: 75%;
    max-width: 90%;
    margin: 0 auto;
    text-align: justify;


}

.news-subtitle {

    color: grey;
    padding-left: 10px;
    font-size: 16px;
    font-weight: normal;
    margin: 0 auto;
    text-align: justify;
}

.news-date {
    font-size: 14px;
    font-weight: normal;
    color: grey;
    margin: 0 auto;
}

.text-col {
    margin: 0 auto;
}

.custom-title-page-news {
    font-size: 20px;
}

.goBackButton {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    width: 150px;
    height: 40px;
    color: white;
    background-color: #009986;
    transition: background-color 0.3s ease;
    margin-left: 190px;
}

.goBackButton:hover {
    background-color: var(--v-primary-base);
}
</style>
